<template>
  <!-- 大盒子 -->
  <div class="wrap">
    <div class="wrapsTit">订单信息</div>

    <!-- 内容盒子 -->
    <div class="detail">
      <div class="wrapsTits">产品信息</div>
      <div v-for="(chil, chilIndex) in detail.orderParticularsList" :key="chilIndex" class="wrapsCon">
        <img class="warp-img" :src="chil.commodityImg">
        <div class="w-b-100">
          <div class="content-title">{{ chil.commodityName }}</div>
        </div>
        <div class="content-price"><span class="icon">￥</span>{{ chil.commodityPrice }}</div>
      </div>
    </div>

    <!-- 使用优惠券/礼品卡： -->
    <div v-if="couponList && couponList.length > 0" class="detail m-t-20">
      <div class="flex-between-c">
        <div class="wrapsTits">选择优惠券</div>
      </div>

      <div class="couponList">
        <div v-for="(item, index) in couponList" :key="index" class="coupon-item">
          <div class="item-left">
            <div v-if="item.couponType == 1 || item.couponType == 2" class="item-price">
              <span class="unit">￥</span>
              {{ item.couponValue }}
            </div>
            <div v-if="item.couponType == 3" class="item-price">{{ item.fullDiscountRate / 10 }}折</div>
          </div>
          <img class="xuxian" src="@/assets/img/user/xuxian.png">
          <div class="item-right">
            <div>
              <div class="title elp">{{ item.couponName }}</div>
              <div class="item-info m-t-14">
                <!-- 无门槛券 -->
                <div v-if="item.couponType == 1">
                  <!-- 指定 -->
                  <div v-if="item.applicableGoods == 2" class="time">限定商品可用</div>
                  <!-- 通用 -->
                  <div v-else class="time">适用于全部商品！</div>
                </div>
                <!-- 满减券 -->
                <div v-else-if="item.couponType == 2">
                  <div class="time">满{{ item.fullReductionAmount }}元减{{ item.couponValue }}元</div>
                </div>
                <!-- 折扣券 -->
                <div v-else-if="item.couponType == 3">
                  <div class="time">
                    {{
                      item.fullReductionAmount
                      ? `满${item.fullReductionAmount}打${item.fullDiscountRate / 10}折`
                      : `${item.fullDiscountRate / 10}折`
                    }}
                  </div>
                </div>
              </div>
              <div class="time">有效期{{ item.validEndTime | format("yyyy.MM.dd") }}</div>
            </div>
            <div class="item-but" @click.stop="use(item)">
              <i v-if="form.couponId != item.couponCodeId" class="iconfont icon-weixuanzhong" />
              <i v-if="form.couponId == item.couponCodeId" class="iconfont icon-xuanzhong" />
            </div>
          </div>
        </div>
        <!-- <div class="nothing" v-if="!couponList || couponList.length == 0">
          <div class="nothing-text">- 暂无可使用的优惠券 -</div>
          <div class="nothing-go" @click="goNext(`/my/mineCoupon`)">前往领券中心>></div>
        </div> -->
      </div>
    </div>

    <!-- 小标题 支付方式 -->
    <div class="detail m-t-20">
      <div class="wrapsTits">选择支付方式</div>
      <div class="dis_flex m-t-20">
        <div class="wrapse" :class="form.type == 1 ? 'on' : ''" @click="zhifu(1)">
          <el-radio v-model="form.type" class="redRadio" :label="1">
            <img src="@/assets/img/kecheng/zhifuBao.png"> <span>支付宝支付</span>
          </el-radio>
        </div>
        <div class="wrapse" :class="form.type == 2 ? 'on' : ''" @click="zhifu(2)">
          <el-radio v-model="form.type" class="redRadio" :label="2">
            <img src="@/assets/img/kecheng/weixin.png"> <span>微信支付</span></el-radio>
        </div>
      </div>
      <!-- 提示 -->
      <!--  <div class="wrapsf">
        <i class="iconfont icon-tishi-"></i>
        <span>请在2小时以内支付完成，如未完成此订单将自动关闭。需重新购买！</span>
      </div> -->
      <!-- 金额 -->
      <el-divider />
      <div class="wrapsg">
        <span class="spana">需付金额：</span>
        <div class="box">
          <span class="icon">￥</span>
          <span v-if="form.couponType == 1 || form.couponType == 2" class="spanb">{{
            detail.paidPrice - form.discountPrice > 0 ? detail.paidPrice - form.discountPrice : 0
          }}</span>
          <span v-else-if="form.couponType == 3" class="spanb">{{
            (detail.paidPrice * form.fullDiscountRate).toFixed(2)
          }}</span>
          <span v-else class="spanb">{{ detail.paidPrice }}</span>
        </div>
      </div>
      <!-- 立即支付 -->
      <div class="wrapsh">
        <div @click="lijizhifu">立即支付</div>
      </div>
    </div>

    <div id="test" />

    <wx-payment v-if="ifwXpay" :url="payDetail.code_url" :out-trade-no="payDetail.out_trade_no"
      :price="payDetail.total_fee" @invalid="wxPay()" @close="closeComs" />

    <el-dialog :visible.sync="pay" width="368px" append-to-body @close="closeCom">
      <div class="pay-box">
        <i class="iconfont" :class="ifSucces ? 'icon-a-zu16' : 'icon-a-zu17'" />
        <div class="tishi-title">{{ ifSucces ? "支付成功" : "支付失败" }}</div>
        <div class="tishi-tips">
          {{ ifSucces ? "恭喜您，订单支付成功！" : "抱歉，支付失败，请重新支付。" }}
        </div>
        <el-button type="danger" round @click="closeCom">我知道了</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getPcAgreement } from '@/api/login'
import { testPay, pcwxtestPay } from '@/api/home'
import { getUidOrder } from '@/api/user'
import { CouponsClient } from '@/api/mine/couponsClient'
import wxPayment from './wxPayment.vue'
import { getInfo, getDomain } from '@/api/cookies'
const couponsClient = new CouponsClient()
export default {
  components: { wxPayment },
  data() {
    return {
      copyright: {},
      /* { name: "同行转型", key: 1 },   { name: "招商加盟", key: 2 },
    { name: "高校合伙人", key: 3 } */ form: {
        type: 1, // 支付类型
        discountPrice: 0, // 优惠金额
        isInvoice: '1', // 是否需要发拼
        couponId: null // 选择优惠券
      },
      checkboxs: '',
      detail: {}, // 订单信息
      userInfo: '',
      ifwXpay: false, // 微信支付二维码
      payDetail: {}, // 微信支付详情
      couponList: [],
      /* 支付弹窗 */
      pay: false,
      ifSucces: false,
      /* 协议 */
      agreement: null
    }
  },
  async created() {
    this.copyright = getDomain()
    this.userInfo = getInfo()
    await this.loadgetUidOrder()
    if (this.detail.commodityType == 1) {
      this.getPcAgreement()
    }
    this.useDiscountCouponCode()
  },
  methods: {
    // 新增订单接口
    async loadgetUidOrder() {
      await getUidOrder(this.$route.params.orderNumber).then((res) => {
        this.detail = res.msg
      })
    },
    // 查看可使用的优惠券
    useDiscountCouponCode() {
      if (!this.userInfo || !this.detail) {
        return
      }
      const type = this.detail.orderParticularsList.map((item) => {
        return item.commodityType
      })
      const ids = this.detail.orderParticularsList.map((item) => {
        return item.commodityId
      })
      couponsClient
        .useDiscountCouponCode(this.detail.orderPrice, type.join(','), this.userInfo.id, ids.join(','))
        .then((res) => {
          if (res.code == 0) {
            this.couponList = res.data
          }
        })
    },
    // 选择优惠券
    use(item) {
      if (this.form.couponId == item.couponCodeId) {
        this.form.couponType = null
        this.form.fullDiscountRate = null
        this.form.discountPrice = 0
        this.form.couponId = null
      } else {
        this.form.couponType = item.couponType
        this.form.fullDiscountRate = item.fullDiscountRate / 100
        this.form.discountPrice = item.couponValue
        this.form.couponId = item.couponCodeId
      }
    },
    /* 查询是否有协议 */
    getPcAgreement() {
      getPcAgreement(this.detail.commodityId).then((res) => {
        if (res.code == 0) {
        }
      })
    },
    // 支付方式选择
    zhifu(index) {
      this.form.type = index
    },
    // 立即支付
    lijizhifu() {
      if (this.res && this.checkboxs !== true) {
        this.$message.error('请先阅读并同意协议内容！')
      } else if (this.form.isInvoice !== '0' && this.form.isInvoice !== '1') {
        this.$message.error('请选择是否需要发票！')
      } else if (!this.form.type) {
        this.$message.error('请选择支付方式！')
      } else {
        if (this.form.type == 1) {
          // 支付宝支付接口
          testPay(
            this.detail.orderNumber,
            this.detail.paidPrice - this.form.discountPrice > 0
              ? this.detail.paidPrice - this.form.discountPrice
              : 0,
            this.detail.commodityName,
            this.form.isInvoice,
            this.form.couponId
          ).then((res) => {
            if (res.msg == 200) {
              this.$router.push({ path: '/payment', query: { data: res.code } })
            } else if (res.msg == 205) {
              this.pay = true
              this.ifSucces = true
            } else if (res.msg == 500) {
              this.pay = true
              this.ifSucces = false
            }
          })
        } else {
          // 微信支付接口
          this.wxPay()
        }
      }
    },
    /* 我知道了 */
    /* 关闭组件 */
    closeCom() {
      if (this.ifSucces) {
        if (this.copyright.entrantsType == 2) {
          this.$router.push({ path: '/all/mycurriculum' })
        } else if (this.copyright.entrantsType == 1) {
          this.$router.push({ path: '/allsixth/mycurriculumsixth' })
        } else if (this.copyright.entrantsType == 3) {
          this.$router.push({ path: '/seventhPage/mycurriculum' })
        } else if (this.copyright.entrantsType == 5) {
          this.$router.push({ path: '/partnerPage/mycurriculum' })
        }
        /*   */
      } else {
        this.pay = false
      }
    },
    closeComs() {

      if (this.copyright.entrantsType == 2) {
        this.$router.push({ path: '/all/mycurriculum' })
      } else if (this.copyright.entrantsType == 1) {
        this.$router.push({ path: '/allsixth/mycurriculumsixth' })
      } else if (this.copyright.entrantsType == 3) {
        this.$router.push({ path: '/seventhPage/mycurriculum' })
      } else if (this.copyright.entrantsType == 5) {
        this.$router.push({ path: '/partnerPage/mycurriculum' })
      }

    },
    wxPay() {
      pcwxtestPay(
        this.detail.orderNumber,
        this.detail.commodityName,
        this.form.isInvoice,
        this.form.couponId
      ).then((res) => {
        if (res.msg == 200) {
          this.payDetail = res
          this.ifwXpay = true
          this.$forceUpdate()
        } else if (res.msg == 205) {
          this.pay = true
          this.ifSucces = true
        } else if (res.msg == 500) {
          this.pay = true
          this.ifSucces = false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  width: 1220px;
  margin: 40px auto;
}

/* 优惠券列表 */
.couponList {
  min-height: 120px;
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;

  .coupon-item {
    width: 365px;
    height: 104px;
    background: #fff;
    border-radius: 4px;
    display: flex;
    margin-bottom: 12px;

    .item-left {
      width: 103px;
      height: 104px;
      flex-shrink: 0;
      background: #fe7980;
      box-shadow: 0px 1px 6px 0px rgba(133, 133, 133, 0.16);
      border-radius: 4px 0px 0px 4px;

      .item-price {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        line-height: 103px;
        flex-shrink: 0;
        text-align: center;

        /*    display: flex;
            align-items: center;
            justify-content: center; */
        .unit {
          font-size: 16px;
          line-height: 103px;
          font-weight: bold;
        }
      }
    }

    .xuxian {
      width: 1px;
      height: 100%;
    }

    .item-right {
      width: 100%;
      margin-left: 15px;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        margin-top: 23px;
      }

      .time {
        font-size: 11px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff6a6f;
        line-height: 12px;
        margin-top: 6px;
      }

      .item-but {
        flex-shrink: 0;
        margin: auto 0px;
        margin-right: 44px;

        .iconfont {
          font-size: 20px;
        }

        .icon-xuanzhong {
          color: #ff6a6f;
        }
      }
    }
  }

  .nothing {
    min-height: 120px;
  }
}

/deep/.el-dialog {
  .el-dialog__body {
    padding-bottom: 20px !important;
  }
}

.detail {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  padding: 40px;
}

// 订单表格内容
.wrapsCon {
  width: 100%;
  height: 138px;
  background: #f5f7f9;
  border-radius: 8px 8px 8px 8px;

  margin-top: 20px;
  padding: 24px;
  display: flex;

  .warp-img {
    width: 160px;
    height: 90px;
    border-radius: 8px 8px 8px 8px;

    flex-shrink: 0;
    margin-right: 16px;
  }

  .content-title {
    width: 100%;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .content-tips {
    width: 64px;
    height: 20px;
    background: #fff2e9;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 16px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ff7b1c;
    line-height: 20px;
    text-align: center;
  }

  .content-price {
    font-size: 24px;
    font-family: Microsoft YaHei-Bold;
    font-weight: bold;
    color: #333333;
    line-height: 90px;

    .icon {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
      line-height: 14px;
    }
  }
}

// 标题
.wrapsTit {
  font-size: 28px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  margin-bottom: 32px;
}

// 小标题
.wrapsTits {
  font-size: 16px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}

/* 支付提示 */
.pay-box {
  display: flex;
  flex-direction: column;
  align-items: center;

  .iconfont {
    font-size: 80px;
  }

  .icon-a-zu16 {
    color: #5ab4fc;
  }

  .icon-a-zu17 {
    color: #fa6450;
  }

  .tishi-title {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 30px;
    color: #333333;

    margin-top: 40px;
  }

  .tishi-tips {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    line-height: 30px;
    color: #666666;

    margin-top: 24px;
  }

  /deep/ .el-button {
    width: 225px;
    margin-top: 56px;
    height: 46px;
    background: #ff4027;
  }
}

// 支付按钮
.wrapse {
  width: 360px;
  height: 120px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #dddddd;
  cursor: pointer;
  margin-right: 20px;

  /deep/.redRadio {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0px 40px;

    .el-radio__inner {
      width: 24px;
      height: 24px;

      &:after {
        width: 10px;
        height: 10px;
      }
    }

    .el-radio__label {
      align-items: center;
      display: flex;
    }
  }

  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }

  span {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
  }

  &:hover {
    border: 1px solid #ff5e51;
  }
}

.on {
  background: #ffedeb;
  border: 1px solid #ff5e51;
}

// 提示
.wrapsf {
  width: 94%;
  height: 13px;
  margin: auto;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 13px;

  .iconfont {
    font-size: 13px;
    color: #ff9900;
    margin-right: 6px;
  }

  span {
    width: auto;
    height: auto;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ff9900;
  }
}

// 金额
.wrapsg {
  width: 94%;
  margin: auto;
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .spana {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
  }

  .box {
    width: 170px;
    text-align: right;
  }

  .icon {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ff5e51;
    line-height: 18px;
  }

  .spanb {
    font-size: 32px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: Bold;
    color: #ff5e51;
    line-height: 32px;
  }
}

// 立即支付
.wrapsh {
  width: 94%;
  height: 50px;
  margin: auto;
  margin-top: 43px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  div {
    width: 170px;
    height: 48px;
    background: #ff5e51;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
  }
}
</style>
